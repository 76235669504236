import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GeneralLayout from '../components/GeneralLayout';
import TitleBlock from '../components/TitleBlock';
import CategoryMenu from '../components/CategoryMenu';
import ResultBlock from '../components/Media/ResultBlock';

export default function News() {
  const listQuery = useStaticQuery(graphql`
    query {
      allSanityMedia(sort: {fields: date, order: DESC}) {
        nodes {
          date
          externalUrl
          featured
          id
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          mediaCategory {
            title
          }
          otherImages {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          slug {
            current
          }
          title
        }
      }
    }
  `);

  const [activeCategory, setActiveCategory] = useState('Recent News');
  function mapToResultBlocks(media) {
    return media.map((mediaItem) => (
      <ResultBlock
        key={mediaItem.id}
        data={mediaItem}
      />
    ));
  }
  function returnResultsByCategory() {
    return mapToResultBlocks(listQuery.allSanityMedia.nodes.filter((media) => (
      media.featured === 'y')));
  }

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    document.getElementById('___gatsby').style.overflowY = 'auto';
    document.getElementById('gatsby-focus-wrapper').style.overflowY = 'auto';
  }, []);

  return (
    <GeneralLayout>
      <div className="info">
        <TitleBlock place="media" activeCategory={activeCategory} />
        {returnResultsByCategory(activeCategory)}
      </div>
      <CategoryMenu
        categoryType="Media"
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        listQuery={listQuery}
      />
    </GeneralLayout>
  );
}
